import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { shallowEqual, useSelector } from "react-redux";
import * as TYPE from './lib/contrant'



const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const Dashboard = lazy(() =>
  import("./pages/Dashboard")
);
const Solutions = lazy(() => import("./pages/Solutions/ModelCuSolutions"))
const ListSolutions = lazy(() => import("./pages/Solutions/ListSolutions"))
const ListUserAdmin = lazy(() =>
  import("./pages/Admin/ListUserAdmin")
);

const ListRole = lazy(() =>
  import("./pages/Role/ListRole")
);

const ListGroupPermission = lazy(() =>
  import("./pages/GroupPermission/ListGroupPermission")
);

const ListPermission = lazy(() =>
  import("./pages/Permission/ListPermission")
);

const ListUser = lazy(() =>
  import("./pages/User/ListUser")
);

const UserProfile = lazy(() =>
  import("./pages/User/UserProfile")
);

const ChangePassword = lazy(() =>
  import("./pages/User/ChangePassword")
);

const AdminProfile = lazy(() =>
  import("./pages/User/AdminProfile")
);

const DetailPost = lazy(() =>
  import('./pages/Post/DetailPost')
)
const ListPost = lazy(() =>
  import("./pages/Post/ListPost")
);
const ListService = lazy(() =>
  import("./pages/Service/ListService")
);
const ImageService = lazy(() =>
  import("./pages/Image/ListImage")
);
const ListSlideHome = lazy(() =>
  import("./pages/SlideHome/ListSlideHome")
);
const CreateAndUpdateSlideHome = lazy(() =>
  import("./pages/SlideHome/CreateAndUpdateSlideHome")
);
const MemberService = lazy(() =>
  import("./pages/Member/ListMember")
);
const CreateAndUpdateImage = lazy(() => {
  import("./pages/Image/CreateAndUpdateImage")
})
const CreateAndUpdateService = lazy(() =>
  import("./pages/Service/CreateAndUpdateService")
);

const CreateAndUpdateProduct = lazy(() =>
  import("./pages/Product/CreateAndUpdateProduct")
);
const ListTag = lazy(() =>
  import("./pages/Post/ListTag")
);
const ListProduct = lazy(() =>
  import("./pages/Product/ListProduct")
);
const ListFeatureTech = lazy(() =>
  import("./pages/FeatureTech/ListFeatureTech")
)
const ListProjectTag = lazy(() =>
  import("./pages/Project/ListProjectTag")
);

const ListProject = lazy(() =>
  import("./pages/Project/ListProject")
);

const CreateUpdateProject = lazy(() =>
  import("./pages/Project/CreateUpdateProject")
);
const CreateUpdateFeatureTech = lazy(() =>
  import("./pages/FeatureTech/CUFeatureTech")
);
const ListPostCategory = lazy(() =>
  import("./pages/Post/ListCategory")
);

const ListJob = lazy(() =>
  import("./pages/Job/ListJob")
);
const DetailJob = lazy(() =>
  import("./pages/Job/DetailJob")
);
const ListRequest = lazy(() =>
  import("./pages/RequestForm/ListRequest")
);
const UpdateProfile = lazy(() =>
  import("./pages/Profile/UpdateProfile")
);
const ListField = lazy(() => import("./pages/Field/ListField"))
const Field = lazy(() => import("./pages/Field/modelCUField"))

export default function BasePage() {
  const { listUserLoginPermission } = useSelector(state => ({
    listUserLoginPermission: state.authReducer.listUserLoginPermission,
  }), shallowEqual)
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* Trang chủ */}
        <ContentRoute path="/dashboard" component={Dashboard} exact />
        {/* end */}

        <Route path="/user-profile" component={UserProfilepage} exact />

        {/* Quản trị hệ thống */}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_VAI_TRO) && <Route path="/admin/role" component={ListRole} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_QUYEN) && <Route path="/admin/permission" component={ListPermission} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_NHOM_QUYEN) && <Route path="/admin/group-permission" component={ListGroupPermission} exact />}
        {/* end */}
        {/*  quản lý giải pháp */}
        <Route path="/solutions/list" component={ListSolutions} exact />
        <Route path="/solutions/list/:id" component={Solutions} exact />
        {/* Quản lý lĩnh vực */}
        <Route path="/field/list" component={ListField} exact />
        <Route path="/field/list/:id" component={Field} exact />
        {/* Quản lý người dùng */}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_ADMIN) && <Route path="/admin/list-user" component={ListUserAdmin} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_NGUOI_DUNG) && <Route path="/user/list-user" component={ListUser} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_CHI_TIET_NGUOI_DUNG) && <Route path="/user/profile/:id" component={UserProfile} exact />}




        {/* Quản lý đại lý */}
        {/* end */}

        {/* Quản lý admin */}
        {listUserLoginPermission.includes(TYPE.DOI_MAT_KHAU) && <Route path="/admin/change-password" component={ChangePassword} exact />}
        {listUserLoginPermission.includes(TYPE.TRANG_CA_NHAN_ADMIN) && <Route path="/admin/profile" component={AdminProfile} exact />}
        {/* end */}



        {/* end */}


        {listUserLoginPermission.includes(TYPE.QUAN_LY_YEU_CAU) && <Route path="/request/list" component={ListRequest} exact />}

        {listUserLoginPermission.includes(TYPE.QUAN_LY_JOB) && <Route path="/job/list" component={ListJob} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_JOB) && <Route path="/job/detail/:id" component={DetailJob} exact />}

        {/* Quản lý nội dung */}

        {listUserLoginPermission.includes(TYPE.QUAN_LY_NOI_DUNG) && <Route path="/content/list" component={ListPost} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_NOI_DUNG) && <Route path="/content/post/:id/:language_id" component={DetailPost} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_NOI_DUNG) && <Route path="/content/category/list" component={ListPostCategory} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_NOI_DUNG) && <Route path="/content/tag/list" component={ListTag} exact />}
        {/* end */}

        {/* Quản lý dự án */}


        {listUserLoginPermission.includes(TYPE.QUAN_LY_DU_AN) && <Route path="/project/detail/:id" component={CreateUpdateProject} exact />}
        {/* {listUserLoginPermission.includes(TYPE.QUAN_LY_DU_AN) && <Route path="/content/category/list" component={ListPostCategory} exact />}  */}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_DU_AN) && <Route path="/project/tag/list" component={ListProjectTag} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_DU_AN) && <Route path="/project/list" component={ListProject} exact />}


        {/* end */}

        {/* quản lý dịch vụ */}

        {listUserLoginPermission.includes(TYPE.QUAN_LY_DICH_VU) && <Route path="/service/list" component={ListService} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_DICH_VU) && <Route path="/service/create-update" component={CreateAndUpdateService} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_DICH_VU) && <Route path="/service/create-update/:id" component={CreateAndUpdateService} exact />}

        {/* end */}

        {/* quản lý hình ảnh*/}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_HINH_ANH) && <Route path="/image/list" component={ImageService} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_HINH_ANH) && <Route path="/image/create-update" component={CreateAndUpdateImage} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_HINH_ANH) && <Route path="/image/create-update/:id" component={CreateAndUpdateImage} exact />}
        {/* end */}

        {/* quản lý slide home*/}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_SLIDE_HOME) && <Route path="/slide_home/list" component={ListSlideHome} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_SLIDE_HOME) && <Route path="/slide_home/create-update" component={CreateAndUpdateSlideHome} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_SLIDE_HOME) && <Route path="/slide_home/create-update/:id" component={CreateAndUpdateSlideHome} exact />}
        {/* end */}

        {/* quản lý nhân sự*/}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_NHAN_SU) && <Route path="/member/list" component={MemberService} exact />}
        {/* end */}

        {
          // listUserLoginPermission.includes(TYPE.QUAN_LY_NHAN_SU) &&
          <Route path="/profile/" component={UpdateProfile} exact />}

        {/* quản lý sản phẩm*/}
        {<Route path="/product/list" component={ListProduct} exact />}
        {<Route path="/product/create-update" component={CreateAndUpdateProduct} exact />}
        {<Route path="/product/create-update/:id" component={CreateAndUpdateProduct} exact />}

        {/* quản lý công nghệ*/}
        {<Route path="/feature-tech/list" component={ListFeatureTech} exact />}
        {<Route path="/feature-tech/create-update" component={CreateUpdateFeatureTech} exact />}
        {<Route path="/feature-tech/create-update/:id" component={CreateUpdateFeatureTech} exact />}


        <Redirect from="/login" to="/" />
      </Switch>
    </Suspense>
  );
}
