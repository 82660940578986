import { Types } from './types'

const initialState = {
    dataSolutions: {
        rows: [],
        paging: {}
    },

}
const reducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
     
        case Types.SAVE_LIST_SOLUTIONS:
            return {
                dataSolutions: action.payload
            }
      
        default:
            return { ...newState }
    }
}
export default reducer
